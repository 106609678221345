/* global $ */

class App
{
    bootstrap;

    constructor()
    {
        this.disableLogger();
        this.getBootstrap();
        this.initStickyMenu();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($('.toast'));
        this.initForms($('form'));
        this.initFontAwesome();
        this.initMatrixBlock();
        this.initCarousel();
        this.initReadmore();
        $('body').css('opacity', 1);
        console.log('App initialized');
    }

    getBootstrap()
    {
        if (!this.bootstrap) {
            this.bootstrap = import(/* webpackChunkName: "bootstrap" */ 'bootstrap');
        }
        return this.bootstrap;
    }

    disableLogger()
    {
        if (window.Globals.env == 'production') {
            console.log = function() {};
        }
    }

    initStickyMenu() {
        var stickyNav = function () {
            var scrollTop = $(window).scrollTop() + 0;
            if (scrollTop > 100) {
                $('.sticky').addClass('fixed');
            } else {
                $('.sticky').removeClass('fixed');
            }
        };
        stickyNav();
        $(window).scroll(function () {
            stickyNav();
        });
    }

    initReadmore() {
        window.onresize = function(){
            if ($(window).width() < 767) {
                $('.article').readmore({
                    speed: 75,
                    collapsedHeight: 115,
                    moreLink: '<a class="txt-open text-purple display-6 fw-bold" href="#"><span>+</span> Show more</a>',
                    lessLink: '<a class="less-btn text-purple display-6 fw-bold" href="#"><span>-</span> Show Less</a>',
                });
            }
        }
    }

    initFontAwesome()
    {
        if ($('.fa-brands, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-duotone').length) {
            import(/* webpackChunkName: "fontawesome" */ './components/fontawesome');
        }
    }

    initForms($forms)
    {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ './components/form').then(chunk => {
                $.each($forms, (i, elem) => {
                    new chunk.Form($(elem));
                });
            });
        }
    }

    initToasts($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem);
                });
            });
        }
    }

    initTooltips($elems)
    {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false)
    {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += ' alert-dismissible fade show';
            message += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + '</div>').appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false)
    {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }
    // initMatrixBlock() {
    //     if ($('.matrix-content').length) {
    //         import(/* webpackChunkName: "matrixblock" */ './components/matrixblock.js').then(matrixblock => {
    //             new matrixblock.Matrixblock;
    //         });
    //     }
    // }

    initMatrixBlock() {
        if ($('.matrix-block').length) {
            import(/* webPackChunkName: "matrixContent" */ './components/matrixblock');
        }
    }

    initCarousel() {
        import(/* webpackChunkName: "carousel" */ './components/carousel').then(carousel => {
            new carousel.Carousel;
        });
    }

}

export default App;